import { FormControl, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { now } from 'moment-timezone';

import React, { useState } from 'react';
import { Controller, Control, FieldErrors, FieldValues } from 'react-hook-form';

interface IFormDatePicker extends FieldValues {
  name: string;
  control: Control;
  label: string | undefined;
  errors: FieldErrors<FieldValues>;
  required?: boolean;
  defaultValue?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

export const FormDatePicker: React.FC<IFormDatePicker> = ({
  name,
  control,
  label,
  errors,
  required = false,
  defaultValue,
  minDate,
  maxDate,
  disabled,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <FormControl fullWidth sx={{ mt: 2.5 }}>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={disabled}
              format="dd/MM/yyyy"
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              defaultValue={now()}
              views={['day']}
              label={label}
              value={value}
              minDate={minDate}
              maxDate={maxDate}
              onChange={onChange}
              slotProps={{
                popper: {
                  style: {
                    display: open ? '' : 'none',
                    direction: 'ltr',
                  },
                },
                inputAdornment: {
                  position: 'start',
                  sx: {
                    ml: 0.5,
                  },
                },
              }}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    color="info"
                    key={'picker-text-field'}
                    ref={params.inputRef}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    onClick={() => setOpen(!open)}
                    error={!!errors[name]}
                    helperText={!!errors[name] && 'This field is required'}
                    data-cy="components_formDatePicker_field"
                    sx={{
                      '& .MuiInputBase-input': {
                        caretColor: 'transparent',
                        cursor: 'pointer',
                        userSelect: 'none',
                      },
                      label: {
                        color: 'text.secondary',
                        fontWeight: 400,
                      },
                    }}
                  />
                ),
              }}
            />
          </LocalizationProvider>
        )}
      />
    </FormControl>
  );
};
