// external
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';

interface IFormDropdown extends FieldValues {
  name: string;
  control: Control;
  label: string | undefined;
  errors: FieldErrors<FieldValues>;
  options: { label: string | undefined; value: string; disabled?: boolean }[];
  noOptionsLabel?: string;
  required?: boolean;
  defaultValue?: string;
}

/**
 *  @deprecated Use FormDropdownTyped instead
 */
export const FormDropdown: React.FC<IFormDropdown> = ({
  name,
  control,
  label,
  options,
  noOptionsLabel = 'No options available',
  required = false,
  defaultValue = '',
  ...selectProps
}) => {
  const renderSelectOptions = () =>
    options.length !== 0
      ? options.map((option, index) => (
          <MenuItem
            key={`menu-item-${index}`}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))
      : [
          <MenuItem key="no-options-available" value="" disabled={true}>
            {noOptionsLabel}
          </MenuItem>,
        ];

  return (
    <FormControl
      fullWidth
      sx={{
        mt: 2.5,
      }}
    >
      <InputLabel
        id={`${name}-label`}
        sx={{
          color: 'text.secondary',
          fontWeight: 400,
          '&.Mui-focused': {
            color: 'info.main',
          },
        }}
      >
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <Select
            {...selectProps}
            labelId={`${name}-label`}
            sx={{
              label: { color: 'text.secondary', fontWeight: 400 },
              ':dir(rtl)': {
                flexDirection: 'row-reverse',
              },
              ':dir(ltr)': {
                flexDirection: 'row',
              },
              ...selectProps.sx,
            }}
            onChange={onChange}
            value={value}
            label={label}
            fullWidth
            color="info"
          >
            {renderSelectOptions()}
          </Select>
        )}
      />
    </FormControl>
  );
};
